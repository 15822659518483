/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag">
      <div :class="{ 'cursor-drag': draggingCard === true }" class="vx-row">
        <div class="vx-col lg:w-4/12 md:w-4/12 sm:w-5/5">
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4">Virtual Machine Information</h3>
            <div v-for="item of vm_info" class="vx-row mb-1" :key="item.id">
              <div class="vx-col flex flex-row items-center w-1/5 p-3">
                <font-awesome-icon icon="fa-sharp fa-solid fa-server" style="background: #181d2a" class="h-8 w-8 p-4 rounded-full" />
              </div>
              <div class="vx-col w-3/5 p-3">
                <span class="text-xl font-bold my-1 flex flex-row gap-x-2"
                  >VM NAME:
                  <p class="font-medium">{{ item.name }}</p></span
                >
                <span class="text-xl font-bold my-1 flex flex-row gap-x-2"
                  >IP:
                  <p class="font-medium">{{ item.ip }}</p></span
                >
              </div>
              <div class="vx-col flex w-1/5 p-3 items-center">
                <vs-switch v-model="item.value" color="success" />
              </div>
            </div>
          </vx-card>
        </div>
        <div class="vx-col lg:w-8/12 md:w-8/12 sm:w-5/5">
          <vue-command
            id="command_prompt"
            :commands="commands"
            :prompt="'C:\\Users\\' + activeUserInfo.firstName.toLowerCase().replace(/ /g, '') + '>'"
            :show-intro="true"
            class=""
            intro="Microsoft Windows [Version 10.0.18363.1256] (c) 2019 Microsoft Corporation. All rights reserved."
            style="background-color: #111111; border: 1px solid #181d2a; border-radius: 0.5rem"
            title="Command Prompt"
          />
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import VueCommand, { createStdout, createStderr } from 'vue-command';
import 'vue-command/dist/vue-command.css';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },

      vm_info: [
        {
          name: 'MS1',
          ip: '192.168.1.1',
          value: false,
          answer: true,
        },
        {
          name: 'DC1',
          ip: '192.168.1.5',
          value: false,
          answer: false,
        },
        {
          name: 'AS1',
          ip: '192.168.1.13',
          value: false,
          answer: true,
        },
        {
          name: 'LX1',
          ip: '192.168.1.27',
          value: false,
          answer: true,
        },
        {
          name: 'MC1',
          ip: '192.168.1.29',
          value: false,
          answer: false,
        },
        {
          name: 'KLX1',
          ip: '192.168.1.31',
          value: false,
          answer: true,
        },
        {
          name: 'AS2',
          ip: '192.168.1.36',
          value: false,
          answer: false,
        },
        {
          name: 'NTNX',
          ip: '192.168.1.44',
          value: false,
          answer: true,
        },
        {
          name: 'PKT',
          ip: '192.168.1.48',
          value: false,
          answer: false,
        },
      ],

      commands: {
        help: () => createStdout('Available commands:<br>&nbsp;nmap [args] [target]<br>'),
        nmap(x) {
          if (!x.s || !x.n) {
            return createStderr('Invalid Usage: nmap [args] [target]');
          }

          if (x.n === '192.168.1.1-50') {
            return createStdout(`<br><br><br>-----------------------------------------------<br>Starting Nmap 7.91 ( https://nmap.org ) at 2021-09-21 14:30 EDT<br>
Nmap scan report for 192.168.1.1
Host is up (0.0010s latency).<br>

Nmap scan report for 192.168.1.13
Host is up (0.0020s latency).<br>

Nmap scan report for 192.168.1.27
Host is up (0.0030s latency).<br>

Nmap scan report for 192.168.1.31
Host is up (0.0040s latency).<br>

Nmap scan report for 192.168.1.44
Host is up (0.0050s latency).<br>

Nmap done: 5 IP addresses (5 hosts up) scanned in 0.10 seconds<br>
-----------------------------------------------<br><br><br>`);
          }

          return createStdout('Invalid IP');
        },
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    markExercise() {
      let totalScore = 0;
      let simulation_bad = false;

      this.vm_info.forEach((x) => {
        if (x.value === x.answer && x.answer) {
          totalScore++;
        }
        if (x.value !== x.answer && !x.answer) {
          simulation_bad = true;
        }
      });

      if (simulation_bad) {
        this.$emit('simulation_exercise_getting_there_bad', 0, false);
        return this.$vs.dialog({
          color: 'danger',
          title: 'Invalid VMs Selected!',
          text: "You selected some of the VMs that weren't supposed to be checked, try again.",
        });
      }

      if (totalScore === 5) {
        return this.$emit('simulation_exercise_correct', totalScore);
      }

      this.$emit('simulation_exercise_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
    VueCommand,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
